@import './variable.less';
@import '~antd/dist/antd.less';

* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
}

h1, h2, h3, h4, h5, h6 {
  margin: 0;
  color: @white;
}

button, input, optgroup, select, textarea {
  outline: 0;
  border: none;
  font-size: inherit;
  font-family: inherit;
}

dd, dl, ol, ul {
  margin: 0;
  padding: 0;
}

em, i {
  font-style: normal;
}

ul {
  list-style: none;
}

div:focus, li:focus, span:focus {
  outline: none;
}

a, a:link, a:visited, a:active, a:hover {
  text-decoration: none;
  color: inherit;
  outline: none;
}

table {
  border-collapse: collapse;
}

html, body, #root {
  width: 100%;
  height: 100%;
  color: @white;
  background-color: @deep;
}

@media screen and (max-width: 1400px) {
  html {
    font-size: 15px;
  }
}

@media screen and (max-width: 1200px) {
  html {
    font-size: 14px;
  }
}

@media screen and (max-width: 1000px) {
  html {
    font-size: 13px;
  }
}

@media screen and (max-width: 750px) {
  html {
    font-size: 12px;
  }
}

.bg-active {
  background-color: @active;
}

.bg-dark {
  background-color: @dark;
}

.color-primary {
  color: @primary;
}

.color-success {
  color: @success;
}

.color-success-linear {
  background-image: linear-gradient(to right, @white, @success);
  background-clip: text;
  color: transparent;
}

.color-info {
  color: @info;
}

.w-50 {
  width: 50%;
}

.w-100 {
  width: 100%;
}

.h-100 {
  height: 100%;
}

.overflow-hidden {
  overflow: hidden;
}

.d-inline-block {
  display: inline-block;
}

.d-flex {
  display: flex;
}

.flex-column {
  .d-flex;
  flex-direction: column;
}

.align-items-center {
  align-items: center;
}

.align-items-end {
  align-items: flex-end;
}

.justify-content-center {
  justify-content: center;
}

.justify-content-around {
  justify-content: space-around;
}

.justify-content-between {
  justify-content: space-between;
}

.justify-content-end {
  justify-content: flex-end;
}

.flex-item {
  .d-flex;
  .flex-label {
    flex: 0 0 auto;
  }
  .flex-content {
    flex: 1;
    &:not(.no-overflow) {
      overflow: auto auto;
    }
  }
}

.flex-vertical {
  .h-100;
  .flex-column;
  overflow-y: auto;
  .flex-header, .flex-footer {
    flex: 0 0 auto;
  }
  .flex-content {
    flex: 1;
    &:not(.no-overflow) {
      overflow: hidden auto;
    }
    &.overflow-hidden {
      overflow: hidden;
    }
  }
}

.flex-center-center {
  .d-flex;
  .align-items-center;
  .justify-content-center;
}

.mt-session {
  margin-top: 12rem;
}

.m-5 {
  margin: 2rem;
}

.mx-5 {
  margin-left: 2rem;
  margin-right: 2rem;
}

.my-5 {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.mt-5 {
  margin-top: 2rem;
}

.mb-5 {
  margin-bottom: 2rem;
}

.ml-5 {
  margin-left: 2rem;
}

.mr-5 {
  margin-right: 2rem;
}

.m-4 {
  margin: 1.5rem;
}

.mx-4 {
  margin-left: 1.5rem;
  margin-right: 1.5rem;
}

.my-4 {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

.mt-4 {
  margin-top: 1.5rem;
}

.mb-4 {
  margin-bottom: 1.5rem;
}

.ml-4 {
  margin-left: 1.5rem;
}

.mr-4 {
  margin-right: 1.5rem;
}

.m-3 {
  margin: 1rem;
}

.mx-3 {
  margin-left: 1rem;
  margin-right: 1rem;
}

.my-3 {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.mt-3 {
  margin-top: 1rem;
}

.mb-3 {
  margin-bottom: 1rem;
}

.ml-3 {
  margin-left: 1rem;
}

.mr-3 {
  margin-right: 1rem;
  z-index: 99;
}

.m-2 {
  margin: 0.5rem;
}

.mx-2 {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}

.my-2 {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.mt-2 {
  margin-top: 0.5rem;
}

.mb-2 {
  margin-bottom: 0.5rem;
}

.ml-2 {
  margin-left: 0.5rem;
}

.mr-2 {
  margin-right: 0.5rem;
}

.m-1 {
  margin: 0.25rem;
}

.mx-1 {
  margin-left: 0.25rem;
  margin-right: 0.25rem;
}

.my-1 {
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;
}

.mt-1 {
  margin-top: 0.25rem;
}

.mb-1 {
  margin-bottom: 0.25rem;
}

.ml-1 {
  margin-left: 0.25rem;
}

.mr-1 {
  margin-right: 0.25rem;
}

.p-6 {
  padding: 3rem;
}

.px-6 {
  padding-left: 3rem;
  padding-right: 3rem;
}

.py-6 {
  padding-top: 3rem;
  padding-bottom: 3rem;
}

.pt-6 {
  padding-top: 3rem;
}

.pb-6 {
  padding-bottom: 3rem;
}

.pl-6 {
  padding-left: 3rem;
}

.pr-6 {
  padding-right: 3rem;
}

.p-5 {
  padding: 2rem;
}

.px-5 {
  padding-left: 2rem;
  padding-right: 2rem;
}

.py-5 {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.pt-5 {
  padding-top: 2rem;
}

.pb-5 {
  padding-bottom: 2rem;
}

.pl-5 {
  padding-left: 2rem;
}

.pr-5 {
  padding-right: 2rem;
}

.p-4 {
  padding: 1.5rem;
}

.px-4 {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.py-4 {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

.pt-4 {
  padding-top: 1.5rem;
}

.pb-4 {
  padding-bottom: 1.5rem;
}

.pl-4 {
  padding-left: 1.5rem;
}

.pr-4 {
  padding-right: 1.5rem;
}

.p-3-5 {
  padding: 1.25rem;
}

.px-3-5 {
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}

.py-3-5 {
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
}

.pt-3-5 {
  padding-top: 1.25rem;
}

.pb-3-5 {
  padding-bottom: 1.25rem;
}

.pl-3-5 {
  padding-left: 1.25rem;
}

.pr-3-5 {
  padding-right: 1.25rem;
}

.p-3 {
  padding: 1rem;
}

.px-3 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.py-3 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.pt-3 {
  padding-top: 1rem;
}

.pb-3 {
  padding-bottom: 1rem;
}

.pl-3 {
  padding-left: 1rem;
}

.pr-3 {
  padding-right: 1rem;
}

.p-2 {
  padding: 0.5rem;
}

.px-2 {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.py-2 {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.pt-2 {
  padding-top: 0.5rem;
}

.pb-2 {
  padding-bottom: 0.5rem;
}

.pl-2 {
  padding-left: 0.5rem;
}

.pr-2 {
  padding-right: 0.5rem;
}

.p-1 {
  padding: 0.25rem;
}

.px-1 {
  padding-left: 0.25rem;
  padding-right: 0.25rem;
}

.py-1 {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}

.pt-1 {
  padding-top: 0.25rem;
}

.pb-1 {
  padding-bottom: 0.25rem;
}

.pl-1 {
  padding-left: 0.25rem;
}

.pr-1 {
  padding-right: 0.25rem;
}

.cursor-pointer {
  cursor: pointer;
}

.text-bold {
  font-weight: bold;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.text-max {
  font-size: 2.8rem; // 45
}

.text-ml {
  font-size: 2.2rem; // 35.2
}

.text-lg {
  font-size: 1.75rem; // 28
}

.text-normal {
  font-size: 1.625rem; // 26
}

.text-plus {
  font-size: 1.5rem; // 24
}

.text-md {
  font-size: 1.375rem; // 22 
}

.text-main {
  font-size: 1.25rem; // 20
}

.text-title {
  font-size: 1.125rem; // 18
}

.text-sub {
  font-size: 1rem; // 16
}

.text-item {
  font-size: 0.9375rem; // 15
}

.text-alert {
  font-size: 0.875rem; // 14
}

.text-des {
  font-size: 0.8125rem; // 13
}

.text-mini {
  font-size: 0.75rem; // 12
}

.text-min {
  font-size: .625rem; // 10
}

.text-zero {
  font-size: 0;
}

.slim-scroll {
  &::-webkit-scrollbar-track-piece {
		background-color: @deep;
	}
	
	&::-webkit-scrollbar {
		width: 0.6rem;
	}

	&::-webkit-scrollbar-thumb {
		background-color: #6d677b;
    border-radius: 0.6rem;
  }
}

.slim-no-scroll {
	&::-webkit-scrollbar {
		width: 0px;
	}
}

.break-all {
  word-break: break-all;
}

.white-space-nowrap {
  white-space: nowrap;
}

.ellipsis-text {
  overflow: hidden;
  text-overflow: ellipsis;
  .white-space-nowrap;
}

.border-box {
  box-sizing: border-box;
}

.round-lg {
  border-radius: 2rem;
}

.round-md {
  border-radius: 1rem;
}

.round-sm {
  border-radius: 0.5rem;
}

.position-relative {
  position: relative;
}

.position-absolute {
  position: absolute;
}

.absolute-center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.ant-btn {
  height: auto !important;
  line-height: 1 !important;
  font-size: 1rem !important;
  font-weight: bold;
  padding: 0.5rem 1rem !important;
  color: @white !important;

  &.ant-btn-round {
    .round-lg !important;
  }
  &.ant-btn-lg {
    padding: 1rem 2rem !important;
  }

  &.ant-btn-primary {
    background: linear-gradient(to left, @primary, @success) !important;
    &.ant-btn-dangerous {
      background: linear-gradient(to left, #00935B, #87d068) !important;
    }
    &:not([disabled]) {
      &:hover {
        filter: brightness(1.1);
      }
      &:active {
        filter: brightness(0.9);
      }
    }
    &[disabled] {
      opacity: 0.66;
    }
  }
}

@primary-color: #6dbff3;@error-color: #87d068;