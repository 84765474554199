@import './assets/style/variable.less';

@keyframes shine {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0.5;
  }
}

header {
  background: @deep;
  box-shadow: 0 0 0.6rem rgba(32, 33, 42, 0.5);
  .logo-box {
    width: 4rem;
    height: 4rem;
    border-radius: 50%;
    background-color: #fff;
    .logo {
      width: 100%;
      height: 100%;
    }
  }

  .menu-list {
    a {
      div {
        transition: transform ease-in-out 200ms;
      }
      &:hover {
        color: @success;
        div {
          transform: scale(1.1);
        }
      }
    }
  }
}

main {
  .banner-box {
    .banner-content {
      position: relative;
      z-index: 2;
      .content {
        position: absolute;
        top: 15%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
    .bg {
      position: absolute;
      z-index: 1;
      background-size: 100%;
      background-repeat: no-repeat;
      transform: translate(-50%, 0);
      &.bg1 {
        top: -6%;
        left: 4%;
        width: 20rem;
        height: 20rem;
        background-image: url('./assets/img/circle1.png');
        transform: translate(-50%, 0);
        animation: shine 2s linear 0s alternate infinite;
      }
      &.bg2 {
        top: 6%;
        right: 2%;
        width: 28rem;
        height: 28rem;
        background-image: url('./assets/img/circle.png');
        transform: translate(50%, 0);
        animation: shine 2s linear 500ms alternate infinite;
      }
    }
  }

  .invite-box {
    .input-box {
      background-color: @dark;
      height: 3.2rem;
      line-height: 3.2rem;
      border-radius: 3.2rem;
      input {
        font-size: 1.2rem;
        background: transparent;
        color: @white;
        &::placeholder {
          color: @white;
        }
      }
    }
  }

  .balance-box {
    .balance-content {
      background-color: @dark;
      .icon {
        width: 2rem;
      }
      .des {
        width: 13rem;
      }
      input {
        height: 2.4rem;
        line-height: 2.4rem;
        font-size: 1rem;
        padding: 1rem;
        border-radius: 0.25rem;
        background-color: rgba(255, 255, 255, 0.1);
      }
    }
    .img-wrapper {
      width: 20rem;
      .img-box {
        background: linear-gradient(45deg, @primary, @success);
        border-radius: 50%;
        .img-content {
          background: @deep;
          border-radius: 50%;
        }
      }
    }
  }

  .recommend-box {
    padding-top: 12rem;
    background: url('./assets/img/shadow.png');
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: -6rem -2rem;
    .recommend-content {
      .content-box {
        background: @active;
        padding-top: 6rem;
        padding-bottom: 6rem;
        .input-box {
          background-color: @dark;
          max-width: 66rem;
          margin: 0 auto;
          height: 6rem;
          line-height: 6rem;
          border-radius: 6rem;
          input {
            font-size: 1.2rem;
            background: transparent;
            color: @white;
            &::placeholder {
              color: @white;
            }
          }
        }
      }
    }
  }

  .squid-box {
    background-color: @dark;
    padding-top: 5rem;
    padding-bottom: 20rem;
    .squid-content {
      .squid-item {
        width: 33%;
        .top {
          padding-top: 2.5rem;
          padding-bottom: 3.5rem;
          .title {
            position: absolute;
            top: 0.5rem;
            left: 1rem;
          }
        }
        .position {
          position: absolute;
          bottom: -5rem;
          transform: translate(0, 50%);
          .position-content {
            background: @deep;
            height: 16rem;
          }
        }
        &:nth-of-type(1) {
          .top {
            background-color: @yellow;
          }
        }
        &:nth-of-type(2) {
          .top {
            background-color: #B3B3B3;
          }
          
        }
        &:nth-of-type(3) {
          .top {
            background-color: #D54F49;
          }
          
        }
      }
    }
  }
}

footer {
  .logo-content {
    padding-top: 10rem;
    padding-bottom: 10rem;
    .img-wrapper {
      width: 8rem;
      height: 8rem;
      border-radius: 50%;
      overflow: hidden;
      .img-box {
        background-color: #fff;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
  .foot-content {
    border-top: 1px solid rgba(255, 255, 255, 0.5);
    .logo {
      width: 3rem;
      height: 3rem;
    }
    a {
      &:hover {
        color: @success;
      }
    }
  }
}
@primary-color: #6dbff3;@error-color: #87d068;